<template>
  <div>
    <b-row
      ><b-col sm="8" md="8" lg="6" class="offset-sm-2 offset-md-2 offset-lg-3">
        <b-card>
          <div id="payment-request-button" />
          <form id="payment-form" ref="payment-form" class="mt-2">
            <div id="card-element" />
            <b-button id="submit" variant="primary" class="my-2" :disabled="isLoading">
              <div id="spinner" class="spinner hidden" />
              <span id="button-text">
                Pay now
              </span>
            </b-button>
            <p id="card-error" role="alert" />
            <p class="result-message hidden">
              Payment succeeded, see the result in your
              <a href="" target="_blank">Stripe dashboard.</a> Refresh the page to pay again.
            </p>
          </form>
        </b-card>
      </b-col></b-row
    >
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton } from 'bootstrap-vue'
import { mapState } from 'vuex'

export default {
  name: 'Checkout',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      clientSecret: '',
      stripeStyle: {
        base: {
          color: '#000000',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#888888',
          },
        },
        invalid: {
          fontFamily: 'Arial, sans-serif',
          color: '#b31f00',
          iconColor: '#c62000',
        },
      },
      card: null,
      isLoading: false,
    }
  },
  computed: {
    ...mapState({
      cart: state => state.cart.added,
      cartCount: state =>
        state.cart.added.reduce(
          (item, current) => ({ quantity: item.quantity + current.quantity }),
          { quantity: 0 }
        ).quantity,
    }),
    totalAmount() {
      let total = 0
      this.cart.forEach(i => {
        total += i.totalPrice
      })
      return total
    },
  },
  async created() {
    await this.initPayment()
  },
  methods: {
    submitForm() {
      this.$refs['payment-form'].$el.submit()
    },
    orderComplete() {
      this.loading(false)
      document
        .querySelector('.result-message a')
        .setAttribute('href', 'https://dashboard.stripe.com/payments/')
      document.querySelector('.result-message').classList.remove('hidden')
      document.querySelector('button').disabled = true
    },
    showError(errorMsgText) {
      this.loading(false)
      const errorMsg = document.querySelector('#card-error')
      errorMsg.textContent = errorMsgText
      setTimeout(() => {
        errorMsg.textContent = ''
      }, 4000)
    },
    payWithCard(stripe) {
      this.loading(true)
      stripe
        .confirmCardPayment(this.clientSecret, {
          payment_method: {
            card: this.card,
          },
        })
        .then(result => {
          if (result.error) {
            // Show error to your customer
            this.showError(result.error.message)
          } else {
            // The payment succeeded!
            this.orderComplete(result.paymentIntent.id)
          }
        })
        .finally(() => {
          this.loading(false)
        })
    },
    loading(isLoading) {
      this.isLoading = isLoading
    },
    async initPayment() {
      const paymentRequest = this.$stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Total',
          amount: this.totalAmount * 100,
        },
        requestPayerName: true,
      })

      const elements = this.$stripe.elements()
      const prButton = elements.create('paymentRequestButton', {
        paymentRequest,
      })
      const canMakePayment = await paymentRequest.canMakePayment()
      if (canMakePayment) {
        prButton.mount('#payment-request-button')
      } else {
        document.getElementById('payment-request-button').style.display = 'none'
      }

      const { clientSecret } = (
        await this.$http.post('/stripe/create-payment-intent', this.cart)
      ).data
      this.clientSecret = clientSecret

      paymentRequest.on('paymentmethod', async ev => {
        // eslint-disable-next-line no-use-before-define
        this.loading(true)
        // Confirm the PaymentIntent without handling potential next actions (yet).
        const confirmResult = await this.$stripe.confirmCardPayment(
          clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        )
        if (!confirmResult || confirmResult.error) {
          ev.complete('fail')
          this.showError(confirmResult.error.message)
        } else {
          ev.complete('success')
          if (confirmResult.paymentIntent.status === 'requires_action') {
            this.$stripe.confirmCardPayment(clientSecret).then(result => {
              if (result.error) {
                this.showError(result.error.message)
                // The payment failed -- ask your customer for a new payment method.
              } else {
                // The payment has succeeded.
                this.orderComplete()
              }
            })
          } else {
            // The payment has succeeded.
            this.orderComplete()
          }
        }
      })

      this.card = elements.create('card', { style: this.stripeStyle })
      // Stripe injects an iframe into the DOM
      this.card.mount('#card-element')
      this.card.on('change', event => {
        // Disable the Pay button if there are no card details in the Element
        document.querySelector('button').disabled = event.empty
        document.querySelector('#card-error').textContent = event.error
          ? event.error.message
          : ''
      })
      document.querySelector('#submit').addEventListener('click', event => {
        event.preventDefault()
        // Complete payment when the submit button is clicked
        // eslint-disable-next-line no-use-before-define
        this.payWithCard(this.$stripe, this.card, clientSecret)
      })
    },
  },
}
</script>

<style scoped></style>
